module config {
    export class appConfigUIRoute {
        $inject = ["$routeProvider", '$httpProvider', '$stateProvider', '$urlRouterProvider', '$locationProvider', 'ENV'
            ,'msalAuthenticationServiceProvider'
        ];
        constructor(private $routeProvider: ng.route.IRouteProvider, private $httpProvider: ng.IHttpProvider,
            private $stateProvider: ng.ui.IStateProvider, private $urlRouterProvider: ng.ui.IUrlRouterProvider,
            private $locationProvider: ng.ILocationProvider, private ENV: interfaces.applicationcore.serverConfig
            ,private msalAuthenticationServiceProvider
            ) {

            $httpProvider.interceptors.push("appConfigInterceptor");

            $urlRouterProvider.otherwise("/");

            $stateProvider.state("Home", {
                url: "/home",
                templateUrl: "templates/modules/applicationcore/defaultView.html",
                controller: "defaultCtrl",  
                controllerAs: "defaultCtrl",
                resolve: {
                    getUser: ($rootScope: interfaces.applicationcore.IRootScope, $location: ng.ILocationService) => {
                        if ($rootScope.isLoggedIn === false) {
                            $rootScope.previousPath = $location.path();
                            $location.url("/login");
                        } else {
                            return $rootScope.userFullName;
                        }
                    }
                }
            }).state("login", {
                url: "/login",
                templateUrl: "templates/modules/applicationcore/loginView.html",
                controller: "loginCtrl",
                controllerAs: "loginController"
            }).state("autologin", {
                url: "/autologin",
                templateUrl: "templates/modules/applicationcore/autoLoginView.html",
                controller: "loginCtrl",
                controllerAs: "loginController"
            }).state("azureAD", {
                url: "/azureAD",
                //templateUrl: "templates/modules/applicationcore/changePasswordView.html",
                controller: "azureADCtrl",
                controllerAs: "azureADCtrl"
            }).state("changePassword", {
                url: "/changePassword/{resetToken:string}",
                templateUrl: "templates/modules/applicationcore/changePasswordView.html",
                controller: "changePasswordCtrl",
                controllerAs: "changePasswordCtrl",
                params: {
                    resetToken: { value: null, squash: true }
                }
            }).state("AboutGTS", {
                url: "/AboutGTS",
                templateUrl: "templates/modules/applicationcore/aboutGTSView.html",
                controller: "aboutGTSCtrl",
                controllerAs: "aboutGTSCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'About GTS'
                }
            }).state("auth.PurchaseOrders", {
                url: "/PurchaseOrders",
                templateUrl: "templates/modules/applicationmain/purchase/listView.html",
                controller: "purchaseOrderListCtrl",
                controllerAs: "pOListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Purchase Orders'
                }
            }).state("auth", {
                abstract: true,
                template: '<div ui-view></div>',
                resolve: {
                    getUser: ($rootScope: interfaces.applicationcore.IRootScope, $location: ng.ILocationService) => {
                        if ($rootScope.isLoggedIn === false) {
                            $rootScope.previousPath = $location.path();
                            $location.url("/login");
                        } else {
                            return $rootScope.userFullName;
                        }
                    }
                }
            }).state("unauthorised", {
                url: "/unauthorised",
                params: {
                    url: ""
                },
                templateUrl: "templates/modules/applicationcore/unauthorisedView.html",
                resolve: {},
                controller: 'unauthorisedCtrl',
                controllerAs: 'unauthorisedCtrl'
            }).state("connectionerror", {
                url: "/ConnectionError",
                templateUrl: "templates/modules/applicationcore/ConnectionErrorView.html",
                resolve: {}
            }).state("auth.Default", {
                url: "/",
                templateUrl: "templates/modules/applicationcore/dashboardView.html",
                controller: "dashboardCtrl",
                controllerAs: "dashboardCtrl",
                resolve: {}
            }).state("auth.IFrame", {
                url: "/IFrameWindow/*url",
                templateUrl: "templates/modules/applicationcore/iFrameView.html",
                controller: "iFrameCtrl"
            }).state("auth.PurchaseOrders.Create", {
                url: "/Create",
                templateUrl: "templates/modules/applicationmain/purchase/createView.html",
                controller: "purchaseOrderCreateCtrl",
                controllerAs: "pOCreateCtrl",
                resolve: {
                    CreateFromExisting: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create Purchase Order'
                }
            }).state("auth.PurchaseOrders.CreateExisting", {
                url: "/CreateExisting",
                templateUrl: "templates/modules/applicationmain/purchase/createView.html",
                controller: "purchaseOrderCreateCtrl",
                controllerAs: "pOCreateCtrl",
                resolve: {
                    CreateFromExisting: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create Purchase Order From Existing'
                }
            }).state("auth.PurchaseOrders.CreateChildOrder", {
                url: "/CreateChildOrder",
                templateUrl: "templates/modules/applicationmain/purchase/createChildOrderView.html",
                controller: "purchaseOrderCreateChildOrderCtrl",
                controllerAs: "purchaseOrderCreateChildOrderCtrl",
                resolve: {
                    CreateFromExisting: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create Child Order'
                }
            }).state("auth.PurchaseOrders.Update", {
                url: "/Update/{poId:int}",
                templateUrl: "templates/modules/applicationmain/purchase/updateView.html",
                controller: "purchaseOrderUpdateCtrl",
                controllerAs: "pOUpdateCtrl",
                params: {
                    poId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{pOUpdateCtrl.poNumber ? "PO: " + pOUpdateCtrl.poNumber : "PO"}}'
                }
            }).state("auth.PurchaseOrders.Update.Sku", {
                url: "/sku/{posId:int}",
                templateUrl: "templates/modules/applicationmain/purchase/skuUpdateView.html",
                controller: "purchaseOrderSkuUpdateCtrl",
                controllerAs: "pOSKUUpdateCtrl",
                params: {
                    posId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{pOSKUUpdateCtrl.breadCrumbDesc ? "SKU: " + pOSKUUpdateCtrl.breadCrumbDesc : "SKU"}}'
                }
            }).state("auth.PurchaseOrders.Update.Di", {
                url: "/di/{diId:int}",
                templateUrl: "templates/modules/applicationmain/purchase/diUpdateView.html",
                controller: "deliveryInstructionUpdateCtrl",
                controllerAs: "diUpdateCtrl",
                params: {
                    diId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{diUpdateCtrl.breadCrumbDesc ? "DI: " + diUpdateCtrl.breadCrumbDesc : "DI"}}'
                }
            }).state("auth.PurchaseOrders.Update.Di.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.DeliveryInstruction
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.diId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.PurchaseOrders.Update.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.PurchaseOrder
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.poId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.PurchaseOrders.Update.Di.SundryItem", {
                url: "/pds/{pdsId:int}",
                templateUrl: "templates/modules/applicationmain/purchase/diSundryItemEdit.html",
                controller: "deliveryInstructionSunUpdateCtrl",
                controllerAs: "pdsSundryItemCtrl",
                params: {
                    pdsId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{pdsSundryItemCtrl.breadCrumbDesc ? "Sundry Item: " + pdsSundryItemCtrl.breadCrumbDesc : "Sundry Item"}}'
                }
            }).state("auth.MasterData", {
                url: "/MasterData?{parentId:int}",
                templateUrl: "templates/modules/applicationmain/master/masterDataView.html",
                controller: "masterDataCtrl",
                controllerAs: "masterDataCtrl",
                params: {
                    parentId: 113
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Master Data'
                }
            }).state("auth.MasterData.IFrame", {
                url: "/IFrameWindow?{name: string}&{url: string}",
                templateUrl: "templates/modules/applicationcore/iFrameView.html",
                controller: "iFrameCtrl",
                controllerAs: "iFrameCtrl",
                ncyBreadcrumb: {
                    label: '{{iFrameCtrl.$stateParams.name}}'
                }
            }).state("auth.MasterData.CostParameterOriginCountry", {
                url: "/CostParameterOriginCountry",
                templateUrl: "templates/modules/applicationmain/costing/costParameterOriginCountryView.html",
                controller: "costParameterOriginCountryCtrl",
                controllerAs: "costParameterOriginCountryCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Country of Origin'
                }
            }).state("auth.MasterData.CostParameterDestinationCountry", {
                url: "/CostParameterDestinationCountry",
                templateUrl: "templates/modules/applicationmain/costing/costParameterDestinationCountryView.html",
                controller: "costParameterDestinationCountryCtrl",
                controllerAs: "costParameterDestinationCountryCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Country of Destination'
                }
            }).state("auth.MasterData.CostParameterRoute", {
                url: "/CostParameterRoute",
                templateUrl: "templates/modules/applicationmain/costing/costParameterRouteView.html",
                controller: "costParameterRouteCtrl",
                controllerAs: "costParameterRouteCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Route'
                }
            }).state("auth.MasterData.CostParameterProductCategory", {
                url: "/CostParameterProductCategory",
                templateUrl: "templates/modules/applicationmain/costing/costParameterProductCategoryView.html",
                controller: "costParameterProductCategoryCtrl",
                controllerAs: "costParameterProductCategoryCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Product Category'
                }
            }).state("auth.MasterData.CostParameterOriginCountryProductCategory", {
                url: "/CostParameterOriginCountryProductCategory",
                templateUrl: "templates/modules/applicationmain/costing/costParameterProductCategoryOriginCountryView.html",
                controller: "costParameterProductCategoryOriginCountryCtrl",
                controllerAs: "costParameterProductCategoryOriginCountryCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Origin Country Product Category'
                }
            }).state("auth.MasterData.CostParameterPurchaseOrderType", {
                url: "/CostParameterPurchaseOrderType",
                templateUrl: "templates/modules/applicationmain/costing/costParameterPurchaseOrderTypeView.html",
                controller: "costParameterPurchaseOrderTypeCtrl",
                controllerAs: "costParameterPurchaseOrderTypeCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Purchase Order Type'
                }
            }).state("auth.MasterData.CostParameterSupplier", {
                url: "/CostParameterSupplier",
                templateUrl: "templates/modules/applicationmain/costing/costParameterSupplierView.html",
                controller: "costParameterSupplierCtrl",
                controllerAs: "costParameterSupplierCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Supplier'
                }
            }).state("auth.MasterData.CostParameterCustomerProductCategory", {
                url: "/CostParameterCustomerProductCategory",
                templateUrl: "templates/modules/applicationmain/costing/costParameterCustomerProductCategoryView.html",
                controller: "costParameterCustomerProductCategoryCtrl",
                controllerAs: "costParameterCustomerProductCategoryCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Customer Product Category'
                }
            }).state("auth.MasterData.CostParameterProductCostGroup", {
                url: "/CostParameterProductCostGroup",
                templateUrl: "templates/modules/applicationmain/costing/costParameterProductCostGroupView.html",
                controller: "costParameterProductCostGroupCtrl",
                controllerAs: "costParameterProductCostGroupCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Product Cost Group'
                }
            }).state("auth.MasterData.CostParameterWarehouseCustomAttribute", {
                url: "/CostParameterWarehouseCustomAttribute",
                templateUrl: "templates/modules/applicationmain/costing/costParameterWarehouseCustomAttributeView.html",
                controller: "costParameterWarehouseCustomAttributeCtrl",
                controllerAs: "costParameterWarehouseCustomAttributeCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Warehouse Custom Attribute'
                }
            }).state("auth.MasterData.CostModels", {
                url: "/CostModels",
                templateUrl: "templates/modules/applicationmain/costing/CostModelListView.html",
                controller: "CostModelListCtrl",
                controllerAs: "CostModelListCtrl",
                resolve: {
                },
                ncyBreadcrumb: {
                    label: 'Cost Models'
                }
            }).state("auth.MasterData.CostModels.Update", {
                url: "/UpdateCostModel/{cosId:int}",
                templateUrl: "templates/modules/applicationmain/costing/CostModelUpdateView.html",
                controller: "CostModelUpdateCtrl",
                controllerAs: "CostModelUpdateCtrl",
                resolve: {},
                params: {
                    cosId: null,
                },
                ncyBreadcrumb: {
                    label: '{{CostModelUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.ProductCustomerReferences", {
                url: "/ProductCustomerReferences",
                templateUrl: "templates/modules/applicationmain/master/productCustomerReferencesView.html",
                controller: "productCustomerReferencesCtrl",
                controllerAs: "prodCusRefCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Product Customer References'
                }
            }).state("auth.MasterData.ProductCosting", {
                url: "/ProductCosting",
                templateUrl: "templates/modules/applicationmain/master/productCostingView.html",
                controller: "productCostingCtrl",
                controllerAs: "productCostingCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Product Destination Country'
                }
            }).state("auth.MasterData.ProductCostingProductCustomer", {
                url: "/ProductCostingProductCustomer",
                templateUrl: "templates/modules/applicationmain/master/productCostingProductCustomerView.html",
                controller: "productCostingProductCustomerCtrl",
                controllerAs: "productCostingProductCustomerCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Costing Parameters - Product Customer'
                }
            }).state("auth.MasterData.CustomsRateOfExchange", {
                url: "/CustomsRateOfExchange",
                templateUrl: "templates/modules/applicationmain/master/customsRateOfExchangeView.html",
                controller: "customsRateOfExchangeCtrl",
                controllerAs: "customsRateOfExchangeCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Customs Rate Of Exchange'
                }
            }).state("auth.MasterData.TariffDownload", {
                url: "/TariffDownload",
                templateUrl: "templates/modules/applicationmain/tariff/tariffBookDownloadView.html",
                controller: "tariffBookDownloadCtrl",
                controllerAs: "tariffBookDownloadCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Tariff Download'
                }
            }).state("auth.MasterData.VariableDocument", {
                url: "/VariableDocumentTemplates",
                templateUrl: "templates/modules/applicationmain/master/variableDocumentView.html",
                controller: "variableDocumentListCtrl",
                controllerAs: "variableDocumentListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Variable Document Templates'
                }
            }).state("auth.MasterData.VariableDocument.Update", {
                url: "/UpdateVariableDocument/{variableDocumentId:int}",
                templateUrl: "templates/modules/applicationmain/master/variableDocumentUpdateView.html",
                controller: "variableDocumentUpdateCtrl",
                controllerAs: "variableDocumentUpdateCtrl",
                resolve: {},
                params: {
                    variableDocumentId: null,
                },
                ncyBreadcrumb: {
                    label: '{{variableDocumentUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.FileTemplate", {
                url: "/FileTemplates",
                templateUrl: "templates/modules/applicationmain/master/fileTemplateView.html",
                controller: "fileTemplateListCtrl",
                controllerAs: "fileTemplateListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'File Templates'
                }
            }).state("auth.MasterData.FileTemplate.Update", {
                url: "/UpdateFileTemplate/{fileTemplateId:int}",
                templateUrl: "templates/modules/applicationmain/master/fileTemplateUpdateView.html",
                controller: "fileTemplateUpdateCtrl",
                controllerAs: "fileTemplateUpdateCtrl",
                resolve: {},
                params: {
                    fileTemplateId: null,
                },
                ncyBreadcrumb: {
                    label: '{{fileTemplateUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Permit", {
                url: "/Permit",
                templateUrl: "templates/modules/applicationmain/master/permitView.html",
                controller: "permitController",
                controllerAs: "permitController",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Permits'
                }
            }).state("auth.MasterData.Permit.Update", {
                url: "/UpdatePermit/{permId:int}",
                templateUrl: "templates/modules/applicationmain/master/permitUpdateView.html",
                controller: "permitUpdateController",
                controllerAs: "permitUpdateController",
                resolve: {},
                params: {
                    permId: null,
                },
                ncyBreadcrumb: {
                    label: '{{permitUpdateController.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Permit.Update.RestrictionDetail", {
                url: "/RestrictionDetail/{parId:int}",
                templateUrl: "templates/modules/applicationmain/tariff/prohibitedAndRestrictedUpdateView.html",
                controller: "prohibitedAndRestrictedUpdateCtrl",
                controllerAs: "prohibitedAndRestrictedUpdateCtrl",
                params: {
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: 'Restriction Detail'
                }
            }).state("auth.MasterData.Permit.Update.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.Permit
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.permId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{soDocumentCtrl.breadCrumbDesc ? soDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.MasterData.ProductColour", {
                url: "/ProductColour",
                templateUrl: "templates/modules/applicationmain/master/productColourDirectiveView.html",
                controller: "productColourDirectiveCtrl",
                controllerAs: "productColourDirectiveCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Product Colour'
                }
            }).state("auth.MasterData.ProductSize", {
                url: "/ProductSize",
                templateUrl: "templates/modules/applicationmain/master/productSizeDirectiveView.html",
                controller: "productSizeDirectiveCtrl",
                controllerAs: "productSizeDirectiveCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Product Size'
                }
            }).state("auth.MasterData.ProductType", {
                url: "/ProductType",
                templateUrl: "templates/modules/applicationmain/master/productTypeView.html",
                controller: "productTypeController",
                controllerAs: "productTypeController",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Product Type'
                }
            }).state("auth.MasterData.Product", {
                url: "/Product?Entity&Items",
                templateUrl: "templates/modules/applicationmain/master/productListView.html",
                controller: "productListCtrl",
                controllerAs: "productListCtrl",
                params: {
                    Entity: null,
                    Items: new Array()
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: 'Product'
                }
            }).state("auth.MasterData.Product.Update", {
                url: "/Update/{proId:int}",
                templateUrl: "templates/modules/applicationmain/master/productUpdateView.html",
                controller: "productUpdateCtrl",
                controllerAs: "productUpdateCtrl",
                params: {
                    proId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{productUpdateCtrl.breadCrumbDesc ? productUpdateCtrl.breadCrumbDesc : "Product"}}'
                }           
            }).state("auth.MasterData.Product.ProductSKUPackaging", {
                url: "/ProductSKUPackaging/{proId:int}",
                templateUrl: "templates/modules/applicationmain/master/productSKUPackagingListView.html",
                controller: "productSKUPackagingListCtrl",
                controllerAs: "productSKUPackagingListCtrl",
                params: {
                    proId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{productSKUPackagingListCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Product.ProductSKUPackaging.Update", {
                url: "/ProductSKUPackagingUpdate/{skuId:int}",
                templateUrl: "templates/modules/applicationmain/master/productSKUPackagingUpdateView.html",
                controller: "productSKUPackagingUpdateCtrl",
                controllerAs: "productSKUPackagingUpdateCtrl",
                resolve: {},
                params: {
                    skuId: null,
                },
                ncyBreadcrumb: {
                    label: '{{productSKUPackagingUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Product.Update.Tariff", {
                url: "/Tariff/{tprId:int}",
                templateUrl: "templates/modules/applicationmain/tariff/updateView.html",
                controller: "productTariffUpdateCtrl",
                controllerAs: "productTariffUpdateCtrl",
                params: {
                    tprId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{productUpdateCtrl.breadCrumbDesc ? "Tariff: " + productUpdateCtrl.breadCrumbDesc : "Product"}}'
                }
            }).state("auth.MasterData.TariffBookNotes", {
                url: "/TariffBookNotes",
                templateUrl: "templates/modules/applicationmain/tariff/tariffBookNotesListView.html",
                controller: "tariffBookNotesListCtrl",
                controllerAs: "tariffBookNotesListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Tariff Book Notes'
                }
            }).state("auth.MasterData.TariffBookNotes.Update", {
                url: "/UpdateTariffBookNotes/{tnnId:int}",
                templateUrl: "templates/modules/applicationmain/tariff/tariffBookNotesUpdateView.html",
                controller: "tariffBookNotesUpdateCtrl",
                controllerAs: "tariffBookNotesUpdateCtrl",
                resolve: {  CopyNote: () => {
                    return 0
                }},
                params: {
                    refId: null,
                   
                },
                ncyBreadcrumb: {
                    label: '{{tariffBookNotesUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.TariffBookNotes.Copy", {
                url: "/CopyTariffBookNotes/{tnnId:int}",
                templateUrl: "templates/modules/applicationmain/tariff/tariffBookNotesUpdateView.html",
                controller: "tariffBookNotesUpdateCtrl",
                controllerAs: "tariffBookNotesUpdateCtrl",
                resolve: {   CopyNote: () => {
                    return 1
                }},
                params: {
                    refId: null,
                 
                },
                ncyBreadcrumb: {
                    label: '{{tariffBookNotesUpdateCtrl.breadCrumbDesc}}'
                }
            })
            .state("auth.MasterData.GoodsDeclarationAmendmentRequestSettings", {
                url: "/GoodsDeclarationAmendmentRequestSettings",
                templateUrl: "templates/modules/applicationmain/master/GoodsDeclarationAmendmentRequestSettingListView.html",
                controller: "goodsDeclarationAmendmentRequestSettingListCtrl",
                controllerAs: "goodsDeclarationAmendmentRequestSettingListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Goods Declaration Amendment Request Settings'
                }
            }).state("auth.MasterData.GoodsReceiptNotes", {
                url: "/GoodsReceiptNotes",
                templateUrl: "templates/modules/applicationmain/master/goodsReceiptNoteListView.html",
                controller: "goodsReceiptNoteListCtrl",
                controllerAs: "goodsReceiptNoteListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Goods Receipt Notes'
                }
            }).state("auth.MasterData.RefundSetting", {
                url: "/RefundSetting",
                templateUrl: "templates/modules/applicationmain/master/refundSettingListView.html",
                controller: "refundSettingListCtrl",
                controllerAs: "refundSettingListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Refund Settings'
                }
            }).state("auth.MasterData.RefundSetting.Update", {
                url: "/UpdateRefundSetting/{refId:int}",
                templateUrl: "templates/modules/applicationmain/master/refundSettingUpdateView.html",
                controller: "refundSettingUpdateCtrl",
                controllerAs: "refundSettingUpdateCtrl",
                resolve: {},
                params: {
                    refId: null,
                },
                ncyBreadcrumb: {
                    label: '{{refundSettingUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Company", {
                url: "/Company",
                templateUrl: "templates/modules/applicationmain/master/companyListView.html",
                controller: "companyListCtrl",
                controllerAs: "companyListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Company'
                }
            }).state("auth.MasterData.Company.Update", {
                url: "/CompanyUpdate/{comId:int}",
                templateUrl: "templates/modules/applicationmain/master/companyUpdateView.html",
                controller: "companyUpdateCtrl",
                controllerAs: "companyUpdateCtrl",
                resolve: {},
                params: {
                    comId: null,
                },
                ncyBreadcrumb: {
                    label: '{{companyUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Supplier", {
                url: "/Supplier",
                templateUrl: "templates/modules/applicationmain/master/supplierListView.html",
                controller: "supplierListCtrl",
                controllerAs: "supplierListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Supplier'
                }
            }).state("auth.MasterData.SupplierGrading", {
                url: "/SupplierGrading",
                templateUrl: "templates/modules/applicationmain/master/supplierGradingListView.html",
                controller: "supplierGradingListCtrl",
                controllerAs: "supplierGradingListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'SupplierGrading'
                }
            }).state("auth.MasterData.SupplierGrading.Update", {
                url: "/SupplierGradingUpdate/{comId:int}",
                templateUrl: "templates/modules/applicationmain/master/supplierGradingUpdateView.html",
                controller: "supplierGradingUpdateCtrl",
                controllerAs: "supplierGradingUpdateCtrl",
                resolve: {},
                params: {
                    comId: null,
                },
                ncyBreadcrumb: {
                    label: '{{supplierGradingUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Supplier.Update", {
                url: "/SupplierUpdate/{comId:int}",
                templateUrl: "templates/modules/applicationmain/master/supplierUpdateView.html",
                controller: "supplierUpdateCtrl",
                controllerAs: "supplierUpdateCtrl",
                resolve: {},
                params: {
                    comId: null,
                },
                ncyBreadcrumb: {
                    label: '{{supplierUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Customer", {
                url: "/Customer",
                templateUrl: "templates/modules/applicationmain/master/customerListView.html",
                controller: "customerListCtrl",
                controllerAs: "customerListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Customer'
                }
            }).state("auth.MasterData.Customer.Update", {
                url: "/CustomerUpdate/{comId:int}",
                templateUrl: "templates/modules/applicationmain/master/customerUpdateView.html",
                controller: "customerUpdateCtrl",
                controllerAs: "customerUpdateCtrl",
                resolve: {},
                params: {
                    comId: null,
                },
                ncyBreadcrumb: {
                    label: '{{customerUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Consignee", {
                url: "/Consignee",
                templateUrl: "templates/modules/applicationmain/master/consigneeListView.html",
                controller: "consigneeListCtrl",
                controllerAs: "consigneeListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Consignee'
                }
            }).state("auth.MasterData.Consignee.Update", {
                url: "/ConsigneeUpdate/{comId:int}",
                templateUrl: "templates/modules/applicationmain/master/consigneeUpdateView.html",
                controller: "consigneeUpdateCtrl",
                controllerAs: "consigneeUpdateCtrl",
                resolve: {},
                params: {
                    comId: null,
                },
                ncyBreadcrumb: {
                    label: '{{consigneeUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.CartageContractor", {
                url: "/CartageContractor",
                templateUrl: "templates/modules/applicationmain/master/cartageContractorListView.html",
                controller: "cartageContractorListCtrl",
                controllerAs: "cartageContractorListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Cartage Contractor'
                }
            }).state("auth.MasterData.CartageContractor.Update", {
                url: "/CartageContractor/{comId:int}",
                templateUrl: "templates/modules/applicationmain/master/cartageContractorUpdateView.html",
                controller: "cartageContractorUpdateCtrl",
                controllerAs: "cartageContractorUpdateCtrl",
                resolve: {},
                params: {
                    comId: null,
                },
                ncyBreadcrumb: {
                    label: '{{cartageContractorUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Bank", {
                url: "/Bank",
                templateUrl: "templates/modules/applicationmain/master/bankListView.html",
                controller: "bankListCtrl",
                controllerAs: "bankListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Bank'
                }
            }).state("auth.MasterData.Bank.Update", {
                url: "/BankUpdate/{bnkId:int}",
                templateUrl: "templates/modules/applicationmain/master/bankUpdateView.html",
                controller: "bankUpdateCtrl",
                controllerAs: "bankUpdateCtrl",
                resolve: {},
                params: {
                    bnkId: null,
                },
                ncyBreadcrumb: {
                    label: '{{bankUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.Shipper", {
                url: "/Shipper",
                templateUrl: "templates/modules/applicationmain/master/shipperListView.html",
                controller: "shipperListCtrl",
                controllerAs: "shipperListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Shipper'
                }
            }).state("auth.MasterData.Shipper.Update", {
                url: "/ShipperUpdate/{shpId:int}",
                templateUrl: "templates/modules/applicationmain/master/shipperUpdateView.html",
                controller: "shipperUpdateCtrl",
                controllerAs: "shipperUpdateCtrl",
                resolve: {},
                params: {
                    shpId: null,
                },
                ncyBreadcrumb: {
                    label: '{{shipperUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.CustomsParty", {
                url: "/CustomsParty",
                templateUrl: "templates/modules/applicationmain/master/customsPartyListView.html",
                controller: "customsPartyListCtrl",
                controllerAs: "customsPartyListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Customs Party'
                }
            }).state("auth.MasterData.CustomsParty.Update", {
                url: "/CustomsPartyUpdate/{antId:int}",
                templateUrl: "templates/modules/applicationmain/master/customsPartyUpdateView.html",
                controller: "customsPartyUpdateCtrl",
                controllerAs: "customsPartyUpdateCtrl",
                resolve: {},
                params: {
                    antId: null,
                },
                ncyBreadcrumb: {
                    label: '{{customsPartyUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.SkuRefundClaimAmounts", {
                url: "/SkuRefundClaimAmounts",
                templateUrl: "templates/modules/applicationmain/master/skuRefundClaimAmountsView.html",
                controller: "skuRefundClaimAmountsController",
                controllerAs: "skuRefundClaimAmountsController",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'SKU Refund Claim Amounts'
                }
            }).state("auth.MasterData.Vessel", {
                url: "/Vessel",
                templateUrl: "templates/modules/applicationmain/master/vesselListView.html",
                controller: "vesselListCtrl",
                controllerAs: "vesselListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Vessel'
                }
            }).state("auth.MasterData.Vessel.Update", {
                url: "/VesselUpdate/{vesId:int}",
                templateUrl: "templates/modules/applicationmain/master/vesselUpdateView.html",
                controller: "vesselUpdateCtrl",
                controllerAs: "vesselUpdateCtrl",
                resolve: {},
                params: {
                    vesId: null,
                },
                ncyBreadcrumb: {
                    label: '{{vesselUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.MasterData.RequirementGroup", {
                url: "/RequirementGroup",
                templateUrl: "templates/modules/applicationmain/master/requirementGroupView.html",
                controller: "requirementGroupListCtrl",
                controllerAs: "requirementGroupListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Requirement Group'
                }
            }).state("auth.MasterData.RequirementGroup.Update", {
                url: "/RequirementGroupUpdate/{requirementGroupId:int}",
                templateUrl: "templates/modules/applicationmain/master/requirementGroupUpdateView.html",
                controller: "requirementGroupUpdateCtrl",
                controllerAs: "requirementGroupUpdateCtrl",
                resolve: {},
                params: {
                    requirementGroupId: null,
                },
                ncyBreadcrumb: {
                    label: '{{requirementGroupUpdateCtrl.breadCrumbDesc}}'
                }
            })
            .state("auth.MasterData.Terminal", {
                url: "/Terminal",
                templateUrl: "templates/modules/applicationcore/terminalCodeListView.html",
                controller: "terminalCodeListCtrl",
                controllerAs: "terminalCodeListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Terminal'
                }
            }).state("auth.MasterData.Terminal.Update", {
                url: "/Terminal/{terminalId:int}",
                templateUrl: "templates/modules/applicationcore/terminalCodeUpdateView.html",
                controller: "terminalCodeUpdateCtrl",
                controllerAs: "terminalCodeUpdateCtrl",
                params: {
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: '{{terminalCodeUpdateCtrl.breadCrumbDesc ? terminalCodeUpdateCtrl.breadCrumbDesc : "Terminal"}}'
                }
            }).state("auth.MasterData.LiveTrackingSetting", {
                url: "/LiveTrackingSetting",
                templateUrl: "templates/modules/applicationmain/logistics/liveTrackingSettingListView.html",
                controller: "liveTrackingSettingListCtrl",
                controllerAs: "liveTrackingSettingListCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Live Tracking Setting'
                }
            }).state("auth.MasterData.LiveTrackingSetting.Update", {
                url: "/{liveTrackingSettingId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/liveTrackingSettingUpdateView.html",
                controller: "livetrackingSettingUpdateCtrl",
                controllerAs: "livetrackingSettingUpdateCtrl",
                params: {
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: '{{livetrackingSettingUpdateCtrl.breadCrumbDesc ? livetrackingSettingUpdateCtrl.breadCrumbDesc : "Live Tracking Setting"}}'
                }
            }).state("auth.MasterData.ShipperIncoterms", {
                url: "/ShipperIncoterms",
                templateUrl: "templates/modules/applicationmain/master/companyIncotermView.html",
                controller: "companyIncotermCtrl",
                controllerAs: "companyIncotermCtrl",
                params: {                  
                },
                resolve: {
                    isInbound: () => {
                        return true
                    }
                },
                ncyBreadcrumb: {
                    label: 'Shipper Incoterms'
                }
            }).state("auth.MasterData.ConsigneeIncoterms", {
                url: "/ConsigneeIncoterms",
                templateUrl: "templates/modules/applicationmain/master/companyIncotermView.html",
                controller: "companyIncotermCtrl",
                controllerAs: "companyIncotermCtrl",
                params: {                  
                },
                resolve: {
                    isInbound: () => {
                        return false
                    }
                },
                ncyBreadcrumb: {
                    label: 'Consignee Incoterms'
                }
            }).state("auth.MasterData.RestrictionOptOut", {
                url: "/RestrictionOptOut",
                templateUrl: "templates/modules/applicationmain/tariff/restrictionOptOutView.html",
                controller: "restrictionOptOutCtrl",
                controllerAs: "restrictionOptOutCtrl",
                params: {                  
                },
                resolve: {          
                },
                ncyBreadcrumb: {
                    label: 'Restriction Opt Out'
                }
            })
            .state("auth.MasterData.PackageQuota", {
                url: "/PackageQuota",
                templateUrl: "templates/modules/applicationmain/master/packageQuotaView.html",
                controller: "packageQuotaCtrl",
                controllerAs: "packageQuotaCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Package Quota'
                }
            }).state("auth.MasterData.PackageQuota.PackageDetail", {
                url: "/PackageDetail/{packageId:int}",
                templateUrl: "templates/modules/applicationmain/master/packageDetailView.html",
                controller: "packageDetailCtrl",
                controllerAs: "packageDetailCtrl",
                params: {
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: '{{packageDetailCtrl.breadCrumbDesc ? packageDetailCtrl.breadCrumbDesc : "Package"}}'
                }
            }).state("auth.MasterData.Packages", {
                url: "/Packages",
                templateUrl: "templates/modules/applicationmain/master/packagesView.html",
                controller: "packagesCtrl",
                controllerAs: "packagesCtrl",
                resolve: {},
                ncyBreadcrumb: {
                    label: 'Packages'
                }
            }).state("auth.MasterData.Packages.PackageDetail", {
                url: "/PackageDetail/{packageId:int}",
                templateUrl: "templates/modules/applicationmain/master/packageDetailView.html",
                controller: "packageDetailCtrl",
                controllerAs: "packageDetailCtrl",
                params: {
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: '{{packageDetailCtrl.breadCrumbDesc ? packageDetailCtrl.breadCrumbDesc : "Package"}}'
                }
            })
            .state("userProfile", {
                url: "/UserProfile",
                templateUrl: "templates/modules/applicationcore/userProfileView.html",
                controller: "userProfileCtrl",
                controllerAs: "userProfileCtrl",
                params: {

                },
                resolve: {}
            }).state("auth.ConsignmentExports", {
                url: "/ConsignmentExports",
                templateUrl: "templates/modules/applicationmain/consignment/listView.html",
                controller: "consignmentListCtrl",
                controllerAs: "consignmentListCtrl",
                params: {
                    FilterList: null,
                    SearchList: null
                },
                resolve: {
                    isInbound: () => {
                        return false
                    }
                },
                ncyBreadcrumb: {
                    label: 'Consignment Export'
                }
            }).state("auth.ConsignmentImports", {
                url: "/ConsignmentImports",
                templateUrl: "templates/modules/applicationmain/consignment/listView.html",
                controller: "consignmentListCtrl",
                controllerAs: "consignmentListCtrl",
                params: {
                    FilterList: null,
                    SearchList: null
                },
                resolve: {
                    isInbound: () => {
                        return true
                    }
                },
                ncyBreadcrumb: {
                    label: 'Consignment Imports'
                }
            }).state("auth.ConsignmentExports.Create", {
                url: "/CreateExport",
                templateUrl: "templates/modules/applicationmain/consignment/createView.html",
                controller: "consignmentCreateCtrl",
                controllerAs: "consignmentCreateCtrl",
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Consignment Export Create New'
                }
            }).state("auth.ConsignmentImports.Create", {
                url: "/CreateImport",
                templateUrl: "templates/modules/applicationmain/consignment/createView.html",
                controller: "consignmentCreateCtrl",
                controllerAs: "consignmentCreateCtrl",
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Consignment Import Create New'
                }
            }).state("auth.ConsignmentExports.CreateExportExisting", {
                url: "/CreateExportExisting",
                templateUrl: "templates/modules/applicationmain/consignment/createExisting.html",
                controller: "consignmentCreateExistingCtrl",
                controllerAs: "consignmentCreateExistingCtrl",
                resolve: {
                    isInbound: () => {
                        return false
                    }
                },
                ncyBreadcrumb: {
                    label: 'Consignment Export From Existing'
                }
            }).state("auth.ConsignmentImports.CreateImportExisting", {
                url: "/CreateImportExisting",
                templateUrl: "templates/modules/applicationmain/consignment/createExisting.html",
                controller: "consignmentCreateExistingCtrl",
                controllerAs: "consignmentCreateExistingCtrl",
                resolve: {
                    isInbound: () => {
                        return true
                    }
                },
                ncyBreadcrumb: {
                    label: 'Consignment Import From Existing'
                }
            }).state("auth.ConsignmentExports.CreateSalesOrder", {
                url: "/CreateSalesOrder",
                templateUrl: "templates/modules/applicationmain/consignment/createSalesOrder.html",
                controller: "consignmentCreateSalesOrderCtrl",
                controllerAs: "consignmentCreateSalesOrderCtrl",
                params: {
                    consignmentId: 0,
                    commercialInvoiceId: 0
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentCreateSalesOrderCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.AddEditCommercialInvoice", {
                url: "/AddEditCommercialInvoice",
                templateUrl: "templates/modules/applicationmain/consignment/createSalesOrder.html",
                controller: "consignmentCreateSalesOrderCtrl",
                controllerAs: "consignmentCreateSalesOrderCtrl",
                params: {
                    consignmentId: 0,
                    commercialInvoiceId: 0
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentCreateSalesOrderCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.CommercialInvoiceCreateNew", {
                url: "/CreateNew/{consignmentId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceCreateNew.html",
                controller: "commercialInvoiceCreateNewCtrl",
                controllerAs: "commercialInvoiceCreateNewCtrl",
                params: {
                    consignmentId: null
                },
                resolve: {
                    isInbound: () => {
                        return false
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create New Commercial Invoice'
                }
            }).state("auth.ConsignmentImports.Update.CommercialInvoiceCreateNew", {
                url: "/CreateNew/{consignmentId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceCreateNew.html",
                controller: "commercialInvoiceCreateNewCtrl",
                controllerAs: "commercialInvoiceCreateNewCtrl",
                params: {
                    consignmentId: null
                },
                resolve: {
                    isInbound: () => {
                        return true
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create New Commercial Invoice'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.CommercialInvoiceCreateNew", {
                url: "/CreateNew/{consignmentId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceCreateNew.html",
                controller: "commercialInvoiceCreateNewCtrl",
                controllerAs: "commercialInvoiceCreateNewCtrl",
                params: {
                    consignmentId: null
                },
                resolve: {
                    isInbound: () => {
                        return true
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create New Commercial Invoice'
                }
            }).state("auth.ConsignmentImports.PurchaseOrder", {
                url: "/CreatePurchaseOrder?{conId:int}{supId:int}{conType:int}",
                templateUrl: "templates/modules/applicationmain/consignment/createPurchaseOrder.html",
                controller: "consignmentCreatePurchaseOrderCtrl",
                controllerAs: "consignmentCreatePurchaseOrderCtrl",
                params: {
                    conId: null,
                    supId: null,
                    conType: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Create Consignment Import From Purchase Order'
                }
            }).state("auth.ConsignmentImports.Update.PurchaseOrder", {
                url: "/CreatePurchaseOrder?{conId:int}{supId:int}{conType:int}",
                templateUrl: "templates/modules/applicationmain/consignment/createPurchaseOrder.html",
                controller: "consignmentCreatePurchaseOrderCtrl",
                controllerAs: "consignmentCreatePurchaseOrderCtrl",
                params: {
                    conId: null,
                    supId: null,
                    conType: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentCreatePurchaseOrderCtrl.disableConsignment ? "Update From Purchase Order" : "Create From Purchase Order"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.POWizard", {
                url: "/CreatePurchaseOrder?{conId:int}{supId:int}{conType:int}",
                templateUrl: "templates/modules/applicationmain/consignment/createPurchaseOrder.html",
                controller: "consignmentCreatePurchaseOrderCtrl",
                controllerAs: "consignmentCreatePurchaseOrderCtrl",
                params: {
                    conId: null,
                    supId: null,
                    conType: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentCreatePurchaseOrderCtrl.disableConsignment ? "Update From Purchase Order" : "Create From Purchase Order"}}'
                }
            }).state("auth.ConsignmentExports.Update", {
                url: "/UpdateExport/{conId:int}",
                templateUrl: "templates/modules/applicationmain/consignment/updateView.html",
                controller: "consignmentUpdateCtrl",
                controllerAs: "consignmentUpdateCtrl",
                params: {
                    conId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.Compliance", {
                url: "/Compliance/{transactionId:int}",
                templateUrl: "templates/modules/applicationmain/compliance/updateView.html",
                controller: "consignmentComplianceCtrl",
                controllerAs: "consignmentComplianceCtrl",
                params: {
                    transactionId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentComplianceCtrl.breadCrumbDesc ? consignmentComplianceCtrl.breadCrumbDesc : "Update Compliance"}}'
                }
            }).state("auth.ConsignmentImports.Update.Compliance", {
                url: "/Compliance/{transactionId:int}",
                templateUrl: "templates/modules/applicationmain/compliance/updateView.html",
                controller: "consignmentComplianceCtrl",
                controllerAs: "consignmentComplianceCtrl",
                params: {
                    transactionId: null,

                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentComplianceCtrl.breadCrumbDesc ? consignmentComplianceCtrl.breadCrumbDesc : "Update Compliance"}}'
                }
            }).state("auth.ConsignmentExports.Update.Detail", {
                url: "/Detail/{codId:int}",
                templateUrl: "templates/modules/applicationmain/consignment/detailUpdateView.html",
                controller: "consignmentDetailUpdateCtrl",
                controllerAs: "codUpdateCtrl",
                params: {
                    codId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{codUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.Container", {
                url: "/Container/{contId:int}",
                templateUrl: "templates/modules/applicationmain/master/addContainerView.html",
                controller: "addContainerController",
                controllerAs: "addConCtrl",
                params: {
                    contId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    isManifest: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Container - {{addConCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.CostInvoice", {
                url: "/CostInvoice/{comId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/costInvoiceUpdateView.html",
                controller: "costInvoiceCtrl",
                controllerAs: "costInvoiceCtrl",
                params: {
                    comId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{costInvoiceCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.CostInvoice.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.CostInvoice
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.comId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentExports.Update.CostInvoice.SundryItem", {
                url: "/SundryItem/{cisId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                controller: "commercialInvoiceSundryItemCtrl",
                controllerAs: "comSunCtrl",
                params: {
                    cisId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    isCostInvoice: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Sundry Item: {{comSunCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.CommercialInvoice", {
                url: "/CommercialInvoice/{comId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceUpdateView.html",
                controller: "commercialInvoiceUpdateCtrl",
                controllerAs: "commercialInvoiceUpdateCtrl",
                params: {
                    comId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    isManifest: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{commercialInvoiceUpdateCtrl.comNumber ? "Commercial Invoice: " + commercialInvoiceUpdateCtrl.comNumber : "Commercial Invoice"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentExport.CommercialInvoice", {
                url: "/CommercialInvoice/{comId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceUpdateView.html",
                controller: "commercialInvoiceUpdateCtrl",
                controllerAs: "commercialInvoiceUpdateCtrl",
                params: {
                    comId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    isManifest: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{commercialInvoiceUpdateCtrl.comNumber ? "Commercial Invoice: " + commercialInvoiceUpdateCtrl.comNumber : "Commercial Invoice"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentExport.AddEditCommercialInvoice", {
                url: "/AddEditCommercialInvoice",
                templateUrl: "templates/modules/applicationmain/consignment/createSalesOrder.html",
                controller: "consignmentCreateSalesOrderCtrl",
                controllerAs: "consignmentCreateSalesOrderCtrl",
                params: {
                    consignmentId: 0,
                    commercialInvoiceId: 0
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentCreateSalesOrderCtrl.breadCrumbDesc}}'
                }
            }).state("auth.Manifests.Update.ConsignmentExport.CommercialInvoice.SundryItem", {
                url: "/SundryItem/{cisId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                controller: "commercialInvoiceSundryItemCtrl",
                controllerAs: "comSunCtrl",
                params: {
                    cisId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    isCostInvoice: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{commercialInvoiceSundryItemCtrl.ciSundryItem ? "Sundry Item: " + commercialInvoiceSundryItemCtrl.ciSundryItem.SequenceNumber : "New Sundry Item"}}'
                }
            }).state("auth.ConsignmentExports.Update.CommercialInvoice.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.ExportCommercialInvoice
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.comId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentExports.Update.GoodsDeclaration", {
                url: "/GoodsDeclaration/{gsdId:int}/Version/{version:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationUpdateView.html",
                controller: "goodsDeclarationUpdateCtrl",
                controllerAs: "goodsDeclarationUpdateCtrl",
                params: {
                    gsdId: null,
                    version: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{goodsDeclarationUpdateCtrl.gsdNumber ? "Goods Declaration: " + goodsDeclarationUpdateCtrl.gsdNumber : "Goods Declaration"}}'
                }
            }).state("auth.ConsignmentExports.Update.RefundClaim", {
                url: "/RefundClaim/{refId:int}",
                templateUrl: "templates/modules/applicationmain/customs/refundClaimUpdateView.html",
                controller: "refundClaimUpdateCtrl",
                controllerAs: "refundClaimUpdateCtrl",
                params: {

                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: 'Refund Claim - {{refundClaimUpdateCtrl.refundClaim.ReferenceNumber}}'
                }
            }).state("auth.ConsignmentExports.Update.RefundClaim.GoodsDeclaration", {
                url: "GoodsDeclaration/{gsdId:int}/document/{version:int}",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.GoodsDeclaration
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentExports.Update.RefundClaim.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.Refund
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.refId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentExports.Update.RefundClaim.ConsignmentImport", {
                url: "/UpdateSF/{conId:int}",
                templateUrl: "templates/modules/applicationmain/consignment/updateView.html",
                controller: "consignmentUpdateCtrl",
                controllerAs: "consignmentUpdateCtrl",
                params: {
                    conId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentExports.Update.RefundClaim.PurchaseOrder", {
                url: "/UpdatePO/{poId:int}",
                templateUrl: "templates/modules/applicationmain/purchase/updateView.html",
                controller: "purchaseOrderUpdateCtrl",
                controllerAs: "pOUpdateCtrl",
                params: {
                    poId: null
                },
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{pOUpdateCtrl.poNumber ? "PO: " + pOUpdateCtrl.poNumber : "PO"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentExport.GoodsDeclaration", {
                url: "/GoodsDeclaration/{gsdId:int}/Version/{version:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationUpdateView.html",
                controller: "goodsDeclarationUpdateCtrl",
                controllerAs: "goodsDeclarationUpdateCtrl",
                params: {
                    gsdId: null,
                    version: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{goodsDeclarationUpdateCtrl.gsdNumber ? "Goods Declaration: " + goodsDeclarationUpdateCtrl.gsdNumber : "Goods Declaration"}}'
                }
            }).state("auth.ConsignmentExports.Update.GoodsDeclaration.Documents", {
                url: "/document/{version:int}",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.GoodsDeclaration
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentExport.GoodsDeclaration.Documents", {
                url: "/document/{version:int}",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.GoodsDeclaration
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentExports.Update.GoodsDeclaration.Line", {
                url: "/Line/{dciId:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationLineUpdateView.html",
                controller: "goodsDeclarationLineUpdateCtrl",
                controllerAs: "dciUpdateCtrl",
                params: {
                    dciId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    version: ($stateParams) => {
                        return $stateParams.version;
                    },
                    gsdId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{dciUpdateCtrl.breadCrumbDesc ? "Goods Declaration Detail: " + dciUpdateCtrl.breadCrumbDesc : "Goods Declaration Detail"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentExport.GoodsDeclaration.Line", {
                url: "/Line/{dciId:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationLineUpdateView.html",
                controller: "goodsDeclarationLineUpdateCtrl",
                controllerAs: "dciUpdateCtrl",
                params: {
                    dciId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    version: ($stateParams) => {
                        return $stateParams.version;
                    },
                    gsdId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{dciUpdateCtrl.breadCrumbDesc ? "Goods Declaration Detail: " + dciUpdateCtrl.breadCrumbDesc : "Goods Declaration Detail"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentExport.CommercialInvoice.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.ExportCommercialInvoice
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.comId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentExports.Update.CommercialInvoice.SundryItem", {
                url: "/SundryItem/{cisId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                controller: "commercialInvoiceSundryItemCtrl",
                controllerAs: "comSunCtrl",
                params: {
                    cisId: null
                },
                resolve: {
                    isInbound: () => {
                        return false;
                    },
                    isCostInvoice: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Sundry Item: {{comSunCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentImports.Update", {
                url: "/UpdateImport/{conId:int}",
                templateUrl: "templates/modules/applicationmain/consignment/updateView.html",
                controller: "consignmentUpdateCtrl",
                controllerAs: "consignmentUpdateCtrl",
                params: {
                    conId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{consignmentUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentImports.Update.Detail", {
                url: "/Detail/{codId:int}",
                templateUrl: "templates/modules/applicationmain/consignment/detailUpdateView.html",
                controller: "consignmentDetailUpdateCtrl",
                controllerAs: "codUpdateCtrl",
                params: {
                    codId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{codUpdateCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentImports.Update.Container", {
                url: "/Container/{contId:int}",
                templateUrl: "templates/modules/applicationmain/master/addContainerView.html",
                controller: "addContainerController",
                controllerAs: "addConCtrl",
                params: {
                    contId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    isManifest: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Container - {{addConCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentImports.Update.GoodsDeclaration", {
                url: "/GoodsDeclaration/{gsdId:int}/Version/{version:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationUpdateView.html",
                controller: "goodsDeclarationUpdateCtrl",
                controllerAs: "goodsDeclarationUpdateCtrl",
                params: {
                    gsdId: null,
                    version: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{goodsDeclarationUpdateCtrl.gsdNumber ? "Goods Declaration: " + goodsDeclarationUpdateCtrl.gsdNumber : "Goods Declaration"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.GoodsDeclaration", {
                url: "/GoodsDeclaration/{gsdId:int}/{version:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationUpdateView.html",
                controller: "goodsDeclarationUpdateCtrl",
                controllerAs: "goodsDeclarationUpdateCtrl",
                params: {
                    gsdId: null,
                    version: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{goodsDeclarationUpdateCtrl.gsdNumber ? "Goods Declaration: " + goodsDeclarationUpdateCtrl.gsdNumber : "Goods Declaration"}}'
                }
            }).state("auth.ConsignmentImports.Update.GoodsDeclaration.Line", {
                url: "/Line/{dciId:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationLineUpdateView.html",
                controller: "goodsDeclarationLineUpdateCtrl",
                controllerAs: "dciUpdateCtrl",
                params: {
                    dciId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    version: ($stateParams) => {
                        return $stateParams.version;
                    },
                    gsdId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{dciUpdateCtrl.breadCrumbDesc ? "Goods Declaration Detail: " + dciUpdateCtrl.breadCrumbDesc : "Goods Declaration Detail"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.GoodsDeclaration.Line", {
                url: "/Line/{dciId:int}",
                templateUrl: "templates/modules/applicationmain/customs/goodsDeclarationLineUpdateView.html",
                controller: "goodsDeclarationLineUpdateCtrl",
                controllerAs: "dciUpdateCtrl",
                params: {
                    dciId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    version: ($stateParams) => {
                        return $stateParams.version;
                    },
                    gsdId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{dciUpdateCtrl.breadCrumbDesc ? "Goods Declaration Detail: " + dciUpdateCtrl.breadCrumbDesc : "Goods Declaration Detail"}}'
                }
            }).state("auth.ConsignmentImports.Update.GoodsDeclaration.Documents", {
                url: "/document/{version:int}",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.GoodsDeclaration
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.GoodsDeclaration.Documents", {
                url: "/document/{version:int}",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.GoodsDeclaration
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.gsdId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentImports.Update.CostInvoice", {
                url: "/CostInvoice/{comId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/costInvoiceUpdateView.html",
                controller: "costInvoiceCtrl",
                controllerAs: "costInvoiceCtrl",
                params: {
                    comId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{costInvoiceCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentImports.Update.CostInvoice.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.CostInvoice
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.comId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.ConsignmentImports.Update.CostInvoice.SundryItem", {
                url: "/SundryItem/{cisId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                controller: "commercialInvoiceSundryItemCtrl",
                controllerAs: "comSunCtrl",
                params: {
                    cisId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    isCostInvoice: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{comSunCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentImports.Update.CommercialInvoice", {
                url: "/CommercialInvoice/{comId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceUpdateView.html",
                controller: "commercialInvoiceUpdateCtrl",
                controllerAs: "commercialInvoiceUpdateCtrl",
                params: {
                    comId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    isManifest: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{commercialInvoiceUpdateCtrl.comNumber ? "Commercial Invoice: " + commercialInvoiceUpdateCtrl.comNumber : "Commercial Invoice"}}'
                }
            }).state("auth.ConsignmentImports.Update.CommercialInvoice.SundryItem", {
                url: "/SundryItem/{cisId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                controller: "commercialInvoiceSundryItemCtrl",
                controllerAs: "comSunCtrl",
                params: {
                    cisId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    isCostInvoice: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Sundry Item: {{comSunCtrl.breadCrumbDesc}}'
                }
            }).state("auth.ConsignmentImports.Update.CustomsRecon", {
                url: "/CustomsRecon/{cusId:int}",
                templateUrl: "templates/modules/applicationmain/customs/clearingInstructionReconDetailUpdateView.html",
                controller: "clearingInstructionReconDetailUpdate",
                controllerAs: "clearingInstructionReconDetailUpdate",
                params: {
                    version: null
                },
                resolve: {
                },
                ncyBreadcrumb: {
                    label: 'Customs Recon - {{clearingInstructionReconDetailUpdate.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.HeaderSummary.CPCCode}}'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.CommercialInvoice", {
                url: "/CommercialInvoice/{comId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceUpdateView.html",
                controller: "commercialInvoiceUpdateCtrl",
                controllerAs: "commercialInvoiceUpdateCtrl",
                params: {
                    comId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    isManifest: () => {
                        return true;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{commercialInvoiceUpdateCtrl.comNumber ? "Commercial Invoice: " + commercialInvoiceUpdateCtrl.comNumber : "Commercial Invoice"}}'
                }
            }).state("auth.ConsignmentImports.Update.CommercialInvoice.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.ImportCommercialInvoice
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.comId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.CommercialInvoice.Documents", {
                url: "/document/",
                templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                controller: "moduleDocumentCtrl",
                controllerAs: "moduleDocumentCtrl",
                params: {
                },
                resolve: {
                    type: () => {
                        return Enum.EnumModelType.ImportCommercialInvoice
                    },
                    referenceId: ($stateParams) => {
                        return $stateParams.comId;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                }
            }).state("auth.Manifests.Update.ConsignmentImport.CommercialInvoice.SundryItem", {
                url: "/SundryItem/{cisId:int}",
                templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                controller: "commercialInvoiceSundryItemCtrl",
                controllerAs: "comSunCtrl",
                params: {
                    cisId: null
                },
                resolve: {
                    isInbound: () => {
                        return true;
                    },
                    isCostInvoice: () => {
                        return false;
                    }
                },
                ncyBreadcrumb: {
                    label: 'Sundry Item: {{comSunCtrl.breadCrumbDesc}}'
                }
            })
                .state("auth.Manifests.Update.ConsignmentExport.CommercialInvoice.Sku", {
                    url: "/SKU/{cisId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSkuUpdateView.html",
                    controller: "commercialInvoiceSkuUpdateCtrl",
                    controllerAs: "commercialInvoiceSkuUpdateCtrl",
                    params: {
                        cisId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        },
                        isCostInvoice: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{commercialInvoiceSkuUpdateCtrl.breadCrumbDesc ? "SKU: " + commercialInvoiceSkuUpdateCtrl.breadCrumbDesc : "SKU"}}'
                    }
                })
                .state("auth.Manifests.Update.ConsignmentImport.CommercialInvoice.Sku", {
                    url: "/SKU/{cisId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSkuUpdateView.html",
                    controller: "commercialInvoiceSkuUpdateCtrl",
                    controllerAs: "commercialInvoiceSkuUpdateCtrl",
                    params: {
                        cisId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        },
                        isCostInvoice: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{commercialInvoiceSkuUpdateCtrl.breadCrumbDesc ? "SKU: " + commercialInvoiceSkuUpdateCtrl.breadCrumbDesc : "SKU"}}'
                    }
                })
                .state("auth.ConsignmentExports.Update.CommercialInvoice.Sku", {
                    url: "/SKU/{cisId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSkuUpdateView.html",
                    controller: "commercialInvoiceSkuUpdateCtrl",
                    controllerAs: "commercialInvoiceSkuUpdateCtrl",
                    params: {
                        cisId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        },
                        isCostInvoice: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{commercialInvoiceSkuUpdateCtrl.breadCrumbDesc ? "SKU: " + commercialInvoiceSkuUpdateCtrl.breadCrumbDesc : "SKU"}}'
                    }
                })
                .state("auth.ConsignmentImports.Update.CommercialInvoice.Sku", {
                    url: "/SKU/{cisId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSkuUpdateView.html",
                    controller: "commercialInvoiceSkuUpdateCtrl",
                    controllerAs: "commercialInvoiceSkuUpdateCtrl",
                    params: {
                        cisId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        },
                        isCostInvoice: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{commercialInvoiceSkuUpdateCtrl.breadCrumbDesc ? "SKU: " + commercialInvoiceSkuUpdateCtrl.breadCrumbDesc : "SKU"}}'
                    }
                })
                .state("auth.ConsignmentExports.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.ExportConsignment
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.conId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.ConsignmentImports.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.ImportConsignment
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.conId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.SalesOrders", {
                    url: "/SalesOrders",
                    templateUrl: "templates/modules/applicationmain/sales/listView.html",
                    controller: "salesOrderListCtrl",
                    controllerAs: "salesOrderListCtrl",
                    resolve: {},
                    ncyBreadcrumb: {
                        label: 'Sales Orders'
                    }
                }).state("auth.SalesOrders.Update", {
                    url: "/SalesOrders/Update/{soId:int}",
                    templateUrl: "templates/modules/applicationmain/sales/updateView.html",
                    controller: "salesOrderUpdateCtrl",
                    controllerAs: "salesOrderUpdateCtrl",
                    params: {
                        soId: null
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: '{{salesOrderUpdateCtrl.soNumber ? "SO: " + salesOrderUpdateCtrl.soNumber : "SO"}}'
                    }
                }).state("auth.SalesOrders.Update.SundryItem", {
                    url: "/sos/{sosId:int}",
                    templateUrl: "templates/modules/applicationmain/sales/soSundryItemEdit.html",
                    controller: "salesOrderSunUpdateCtrl",
                    controllerAs: "salesOrderSunUpdateCtrl",
                    params: {
                        sosId: null
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: '{{salesOrderUpdateCtrl.breadCrumbDesc ? "Sundry Item: " + salesOrderUpdateCtrl.breadCrumbDesc : "Sundry Item"}}'
                    }
                }).state("auth.SalesOrders.Update.Sku", {
                    url: "/sku/{sosId:int}",
                    templateUrl: "templates/modules/applicationmain/sales/skuUpdateView.html",
                    controller: "salesOrderSkuUpdateCtrl",
                    controllerAs: "salesOrderSkuUpdateCtrl",
                    params: {
                        sosId: null
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: '{{salesOrderSkuUpdateCtrl.breadCrumbDesc ? "SKU: " + salesOrderSkuUpdateCtrl.breadCrumbDesc : "SKU"}}'
                    }
                }).state("auth.SalesOrders.Create", {
                    url: "/Create",
                    templateUrl: "templates/modules/applicationmain/sales/createView.html",
                    controller: "salesOrderCreateCtrl",
                    controllerAs: "salesOrderCreateCtrl",
                    resolve: {
                        CreateFromExisting: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Create Sales Order'
                    }
                }).state("auth.SalesOrders.CreateExisting", {
                    url: "/CreateExisting",
                    templateUrl: "templates/modules/applicationmain/sales/createView.html",
                    controller: "salesOrderCreateCtrl",
                    controllerAs: "salesOrderCreateCtrl",
                    resolve: {
                        CreateFromExisting: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Create Sales Order From Existing'
                    }
                }).state("auth.SalesOrders.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.SalesOrder
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.soId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{soDocumentCtrl.breadCrumbDesc ? soDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.ProhibitedAndRestricted", {
                    url: "/ProhibitedAndRestricted",
                    templateUrl: "templates/modules/applicationmain/tariff/prohibitedAndRestrictedView.html",
                    controller: "prohibitedAndRestrictedCtrl",
                    controllerAs: "prohibitedAndRestrictedCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Prohibited And Restricted'
                    }
                }).state("auth.ProhibitedAndRestricted.Update", {
                    url: "/Update/{parId:int}",
                    templateUrl: "templates/modules/applicationmain/tariff/prohibitedAndRestrictedUpdateView.html",
                    controller: "prohibitedAndRestrictedUpdateCtrl",
                    controllerAs: "prohibitedAndRestrictedUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Detail'
                    }
                }).state("auth.ProhibitedAndRestricted.RestrictionDetail", {
                    url: "/RestrictionDetail/{parId:int}",
                    templateUrl: "templates/modules/applicationmain/tariff/prohibitedAndRestrictedUpdateView.html",
                    controller: "prohibitedAndRestrictedUpdateCtrl",
                    controllerAs: "prohibitedAndRestrictedUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Restriction Detail'
                    }
                }).state("auth.ProductTariff", {
                    url: "/ProductTariff?Entity&Items",
                    templateUrl: "templates/modules/applicationmain/tariff/listView.html",
                    controller: "productTariffListCtrl",
                    controllerAs: "productTariffListCtrl",
                    params: {
                        Entity: null,
                        Items: new Array()
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Product Tariff'
                    }
                }).state("auth.ProductTariff.Update", {
                    url: "/Update/{tprId:int}",
                    templateUrl: "templates/modules/applicationmain/tariff/updateView.html",
                    controller: "productTariffUpdateCtrl",
                    controllerAs: "productTariffUpdateCtrl",
                    params: {
                        tprId: null
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: '{{productTariffUpdateCtrl.breadCrumbDesc ? "Product: " + productTariffUpdateCtrl.breadCrumbDesc : "Product"}}'
                    }
                }).state("auth.ProductTariff.Update.RestrictionDetail", {
                    url: "/RestrictionDetail/{parId:int}",
                    templateUrl: "templates/modules/applicationmain/tariff/prohibitedAndRestrictedUpdateView.html",
                    controller: "prohibitedAndRestrictedUpdateCtrl",
                    controllerAs: "prohibitedAndRestrictedUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{productTariffUpdateCtrl.breadCrumbDesc ? "Restriction Detail : " + prohibitedAndRestrictedUpdateCtrl.breadCrumbDesc : "Restriction Detail"}}'
                    }
                }).state("auth.ProductTariff.Update.Product", {
                    url: "/Product/{proId:int}",
                    templateUrl: "templates/modules/applicationmain/master/productUpdateView.html",
                    controller: "productUpdateCtrl",
                    controllerAs: "productUpdateCtrl",
                    params: {
                        proId: null
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{productTariffUpdateCtrl.breadCrumbDesc ? "Product: " + productUpdateCtrl.breadCrumbDesc : "Product"}}'
                    }
                }).state("auth.ProductTariff.MultipleProductTariff", {
                    url: "/MultipleProductTariff",
                    templateUrl: "templates/modules/applicationmain/tariff/mutlipleProductTariffView.html",
                    controller: "multipleProductTariffCtrl",
                    controllerAs: "multipleProductTariffCtrl",
                    params: {
                        productIds: null,
                        tariffCode: null
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: '{{multipleProductTariffCtrl.breadCrumbDesc ?   productTariffUpdateCtrl.breadCrumbDesc : "Mutliple Product Tariff"}}'
                    }
                })
                .state("auth.ProductTariff.Update.Documents", {
                    url: "/document/{version:int}",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                        version: null
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.TariffAmendment
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.tprId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.TariffSearch", {
                    url: "/TariffSearch",
                    templateUrl: "templates/modules/applicationmain/tariff/tariffLookupSearchView.html",
                    controller: "tariffLookupSearchCtrl",
                    controllerAs: "tariffLookupSearchCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Tariff Search'
                    }
                }).state("auth.TariffSearch.RestrictionDetail", {
                    url: "/RestrictionDetail/{parId:int}",
                    templateUrl: "templates/modules/applicationmain/tariff/prohibitedAndRestrictedUpdateView.html",
                    controller: "prohibitedAndRestrictedUpdateCtrl",
                    controllerAs: "prohibitedAndRestrictedUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Restriction Detail'
                    }
                }).state("auth.TariffSearch.GRIDetail", {
                    url: "/GRIDetail",
                    templateUrl: "templates/modules/applicationmain/tariff/tariffGRIDetailView.html",
                    controller: "tariffGRIDetail",
                    controllerAs: "tariffGRIDetail",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'General Rules of Interpretation'
                    }
                }).state("auth.DutyCalculator", {
                    url: "/DutyCalculator",
                    templateUrl: "templates/modules/applicationmain/tariff/tariffCodeCalculateDuties.html",
                    controller: "tariffCodeCalculateDutiesCtrl",
                    controllerAs: "tariffCodeCalculateDutiesCtrl",
                    resolve: {
                        IsLexisNexis: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Duty Calculator'
                    }
                })
                .state("DutyCalculatorLexisNexis", {
                    url: "/DutyCalculatorLexisNexis",
                    templateUrl: "templates/modules/applicationmain/tariff/tariffCodeCalculateDuties.html",
                    controller: "tariffCodeCalculateDutiesCtrl",
                    controllerAs: "tariffCodeCalculateDutiesCtrl",
                    resolve: {
                        IsLexisNexis: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Duty Calculator'
                    }
                }).state("auth.VesselSailingSchedule", {
                    url: "/VesselSailingSchedule",
                    templateUrl: "templates/modules/applicationmain/logistics/vesselScheduleListView.html",
                    controller: "vesselScheduleListCtrl",
                    controllerAs: "vesselScheduleListCtrl",
                    resolve: {},
                    ncyBreadcrumb: {
                        label: 'Vessel Sailing Schedule'
                    }
                }).state("auth.VesselSailingSchedule.Update", {
                    url: "/Update/{vshId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/vesselScheduleUpdateView.html",
                    controller: "vesselScheduleUpdateCtrl",
                    controllerAs: "vesselScheduleUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{vesselScheduleUpdateCtrl.breadCrumbDesc ? vesselScheduleUpdateCtrl.breadCrumbDesc : "Vessel Sailing Schedule"}}'
                    }
                }).state("auth.Manifests", {
                    url: "/Manifests",
                    templateUrl: "templates/modules/applicationmain/manifest/listView.html",
                    controller: "manifestListCtrl",
                    controllerAs: "manifestListCtrl",
                    resolve: {},
                    ncyBreadcrumb: {
                        label: 'Manifests'
                    }
                })
                .state("auth.Manifests.CreateNew", {
                    url: "/CreateNew",
                    templateUrl: "templates/modules/applicationmain/manifest/createView.html",
                    controller: "manifestCreateCtrl",
                    controllerAs: "manifestCreateCtrl",
                    resolve: {
                        FromExisting: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Create New'
                    }
                })
                .state("auth.Manifests.CreateFromExisting", {
                    url: "/CreateFromExisting",
                    templateUrl: "templates/modules/applicationmain/manifest/createView.html",
                    controller: "manifestCreateCtrl",
                    controllerAs: "manifestCreateCtrl",
                    resolve: {
                        FromExisting: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Create From Existing'
                    }
                }).state("auth.Manifests.Update", {
                    url: "/Update/{manId:int}",
                    templateUrl: "templates/modules/applicationmain/manifest/updateView.html",
                    controller: "manifestUpdateCtrl",
                    controllerAs: "manifestUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{manifestUpdateCtrl.breadCrumbDesc ? manifestUpdateCtrl.breadCrumbDesc : "Manifest"}}'
                    }
                }).state("auth.Manifests.Update.Crew", {
                    url: "/Crew/{macId:int}",
                    templateUrl: "templates/modules/applicationmain/manifest/crewUpdateView.html",
                    controller: "manifestCrewUpdateCtrl",
                    controllerAs: "manifestCrewUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{manifestCrewUpdateCtrl.breadCrumbDesc ? manifestCrewUpdateCtrl.breadCrumbDesc : "Crew"}}'
                    }
                }).state("auth.Manifests.Update.Container", {
                    url: "/Container/{contId:int}",
                    templateUrl: "templates/modules/applicationmain/master/addContainerView.html",
                    controller: "addContainerController",
                    controllerAs: "addConCtrl",
                    params: {
                        contId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        },
                        isManifest: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Container - {{addConCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.SundryItem", {
                    url: "/SundryItem/{msiId:int}",
                    templateUrl: "templates/modules/applicationmain/manifest/sundryItemEditView.html",
                    controller: "manifestSundryItemUpdateCtrl",
                    controllerAs: "manifestSundryItemUpdateCtrl",
                    params: {
                        msiId: null,
                        isInbound : null
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: '{{manifestSundryItemUpdateCtrl.breadCrumbDesc ? "Sundry Item: " + manifestSundryItemUpdateCtrl.breadCrumbDesc : "Sundry Item"}}'
                    }
                }).state("auth.Manifests.Update.Documents", {
                    url: "/document/{version:int}",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                        version: null
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.Manifest
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.manId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.Manifests.Update.CreateConsignmentExport", {
                    url: "/CreateExport",
                    templateUrl: "templates/modules/applicationmain/consignment/createView.html",
                    controller: "consignmentCreateCtrl",
                    controllerAs: "consignmentCreateCtrl",
                    resolve: {
                        isInbound: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Consignment Export Create New'
                    }
                }).state("auth.Manifests.Update.CreateConsignmentImport", {
                    url: "/CreateImport",
                    templateUrl: "templates/modules/applicationmain/consignment/createView.html",
                    controller: "consignmentCreateCtrl",
                    controllerAs: "consignmentCreateCtrl",
                    resolve: {
                        isInbound: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Consignment Import Create New'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport", {
                    url: "/UpdateExport/{conId:int}",
                    templateUrl: "templates/modules/applicationmain/consignment/updateView.html",
                    controller: "consignmentUpdateCtrl",
                    controllerAs: "consignmentUpdateCtrl",
                    params: {
                        conId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{consignmentUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport.Detail", {
                    url: "/Detail/{codId:int}",
                    templateUrl: "templates/modules/applicationmain/consignment/detailUpdateView.html",
                    controller: "consignmentDetailUpdateCtrl",
                    controllerAs: "codUpdateCtrl",
                    params: {
                        codId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{codUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport.Container", {
                    url: "/Container/{contId:int}",
                    templateUrl: "templates/modules/applicationmain/master/addContainerView.html",
                    controller: "addContainerController",
                    controllerAs: "addConCtrl",
                    params: {
                        contId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        },
                        isManifest: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Container - {{addConCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport", {
                    url: "/UpdateImport/{conId:int}",
                    templateUrl: "templates/modules/applicationmain/consignment/updateView.html",
                    controller: "consignmentUpdateCtrl",
                    controllerAs: "consignmentUpdateCtrl",
                    params: {
                        conId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{consignmentUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport.Detail", {
                    url: "/Detail/{codId:int}",
                    templateUrl: "templates/modules/applicationmain/consignment/detailUpdateView.html",
                    controller: "consignmentDetailUpdateCtrl",
                    controllerAs: "codUpdateCtrl",
                    params: {
                        codId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{codUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport.Container", {
                    url: "/Container/{contId:int}",
                    templateUrl: "templates/modules/applicationmain/master/addContainerView.html",
                    controller: "addContainerController",
                    controllerAs: "addConCtrl",
                    params: {
                        contId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        },
                        isManifest: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Container - {{addConCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport.CostInvoice", {
                    url: "/CostInvoice/{comId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/costInvoiceUpdateView.html",
                    controller: "costInvoiceCtrl",
                    controllerAs: "costInvoiceCtrl",
                    params: {
                        comId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{costInvoiceCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport.CostInvoice.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.CostInvoice
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.comId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport.CostInvoice.SundryItem", {
                    url: "/SundryItem/{cisId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                    controller: "commercialInvoiceSundryItemCtrl",
                    controllerAs: "comSunCtrl",
                    params: {
                        cisId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        },
                        isCostInvoice: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Sundry Item: {{comSunCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport.CostInvoice", {
                    url: "/CostInvoice/{comId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/costInvoiceUpdateView.html",
                    controller: "costInvoiceCtrl",
                    controllerAs: "costInvoiceCtrl",
                    params: {
                        comId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{costInvoiceCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport.CostInvoice.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.CostInvoice
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.comId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport.CostInvoice.SundryItem", {
                    url: "/SundryItem/{cisId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                    controller: "commercialInvoiceSundryItemCtrl",
                    controllerAs: "comSunCtrl",
                    params: {
                        cisId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        },
                        isCostInvoice: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{comSunCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.ExportConsignment
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.conId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.ImportConsignment
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.conId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.Manifests.Update.CostInvoice", {
                    url: "/CostInvoice/{comId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/costInvoiceUpdateView.html",
                    controller: "costInvoiceCtrl",
                    controllerAs: "costInvoiceCtrl",
                    params: {
                        comId: null,
                        manIsInbound: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{costInvoiceCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Manifests.Update.CostInvoice.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.CostInvoice
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.comId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{moduleDocumentCtrl.breadCrumbDesc ? moduleDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.Manifests.Update.CostInvoice.SundryItem", {
                    url: "/SundryItem/{cisId:int}",
                    templateUrl: "templates/modules/applicationmain/logistics/commercialInvoiceSundryUpdateView.html",
                    controller: "commercialInvoiceSundryItemCtrl",
                    controllerAs: "comSunCtrl",
                    params: {
                        cisId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        },
                        isCostInvoice: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Sundry Item: {{comSunCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Forex", {
                    url: "/Forex",
                    templateUrl: "templates/modules/applicationmain/forex/listView.html",
                    controller: "forexListCtrl",
                    controllerAs: "forexListCtrl",
                    params: {
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: 'Forex Contracts'
                    }
                }).state("auth.Forex.Create", {
                    url: "/CreateForexContract",
                    templateUrl: "templates/modules/applicationmain/forex/createView.html",
                    controller: "forexCreateCtrl",
                    controllerAs: "forexCreateCtrl",
                    params: {},
                    resolve: {},
                    ncyBreadcrumb: {
                        label: 'Create Forex Contract'
                    }
                }).state("auth.Forex.Update", {
                    url: "/UpdateForex/{fecId:int}",
                    templateUrl: "templates/modules/applicationmain/forex/updateView.html",
                    controller: "forexUpdateCtrl",
                    controllerAs: "forexUpdateCtrl",
                    params: {
                        fecId: null
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{forexUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.Forex.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.ForexContract
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.fecId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.SupplierPayments", {
                    url: "/SupplierPayments",
                    templateUrl: "templates/modules/applicationmain/payment/listView.html",
                    controller: "paymentListCtrl",
                    controllerAs: "paymentListCtrl",
                    params: {
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Supplier Payments'
                    }
                }).state("auth.CustomerPayments", {
                    url: "/CustomerPayments",
                    templateUrl: "templates/modules/applicationmain/payment/listView.html",
                    controller: "paymentListCtrl",
                    controllerAs: "paymentListCtrl",
                    params: {
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Customer Payments'
                    }
                }).state("auth.CustomerPayments.Update", {
                    url: "/UpdateCustomerPayment/{payId:int}",
                    templateUrl: "templates/modules/applicationmain/payment/updateView.html",
                    controller: "paymentUpdateCtrl",
                    controllerAs: "paymentUpdateCtrl",
                    params: {
                        payId: null
                    },
                    resolve: {
                        isInbound: function () {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{paymentUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.SupplierPayments.Update", {
                    url: "/UpdateSupplierPayment/{payId:int}",
                    templateUrl: "templates/modules/applicationmain/payment/updateView.html",
                    controller: "paymentUpdateCtrl",
                    controllerAs: "paymentUpdateCtrl",
                    params: {
                        payId: null
                    },
                    resolve: {
                        isInbound: function () {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{paymentUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.CustomerPayments.Update.Invoice", {
                    url: "/UpdateCustomerPaymentInvoice/{payId:int}",
                    templateUrl: "templates/modules/applicationmain/payment/createView.html",
                    controller: "paymentCreateCtrl",
                    controllerAs: "paymentCreateCtrl",
                    params: {
                        payId: null,
                        ownerEntityId : 0,
                        supplierId: 0,
                        customerId: 0,
                        currencyId: 0
                    },
                    resolve: {
                        isInbound: function () {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{paymentCreateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.SupplierPayments.Update.Invoice", {
                    url: "/UpdateSupplierPaymentInvoice/{payId:int}",
                    templateUrl: "templates/modules/applicationmain/payment/createView.html",
                    controller: "paymentCreateCtrl",
                    controllerAs: "paymentCreateCtrl",
                    params: {
                        payId: null,
                        ownerEntityId : 0,
                        supplierId: 0,
                        customerId: 0,
                        currencyId: 0
                    },
                    resolve: {
                        isInbound: function () {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{paymentCreateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.CustomerPayments.Create", {
                    url: "/CreateCustomerPayment/{payId:int}",
                    templateUrl: "templates/modules/applicationmain/payment/createView.html",
                    controller: "paymentCreateCtrl",
                    controllerAs: "paymentCreateCtrl",
                    params: {
                        payId: null
                    },
                    resolve: {
                        isInbound: function () {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{paymentCreateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.SupplierPayments.Create", {
                    url: "/CreateSupplierPayment/{payId:int}",
                    templateUrl: "templates/modules/applicationmain/payment/createView.html",
                    controller: "paymentCreateCtrl",
                    controllerAs: "paymentCreateCtrl",
                    params: {
                        payId: null
                    },
                    resolve: {
                        isInbound: function () {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{paymentCreateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.SupplierPayments.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.SupplierPayment
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.payId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.CustomerPayments.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.CustomerPayment
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.payId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.CustomsRecon", {
                    url: "/CustomsReconList",
                    templateUrl: "templates/modules/applicationmain/customs/clearingInstructionReconDetailListView.html",
                    controller: "clearingInstructionReconDetailListCtrl",
                    controllerAs: "clearingInstructionReconDetailListCtrl",
                    params: {
                    },
                    resolve: {
                        isInbound: () => {
                            return false
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Customs Recon'
                    }
                }).state("auth.CustomsRecon.Update", {
                    url: "/Update/{cusId:int}",
                    templateUrl: "templates/modules/applicationmain/customs/clearingInstructionReconDetailUpdateView.html",
                    controller: "clearingInstructionReconDetailUpdate",
                    controllerAs: "clearingInstructionReconDetailUpdate",
                    params: {
                        version: null
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{clearingInstructionReconDetailUpdate.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.HeaderSummary.ConsignmentNumber}} - {{clearingInstructionReconDetailUpdate.ClearingInstructionRecon.ClearingInstructionReconHeaderDetail.HeaderSummary.CPCCode}}'
                    }
                }).state("auth.RefundClaim", {
                    url: "/RefundClaims",
                    templateUrl: "templates/modules/applicationmain/customs/refundClaimListView.html",
                    controller: "refundClaimListCtrl",
                    controllerAs: "refundClaimListCtrl",
                    params: {
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: 'Refund Claims'
                    }
                }).state("auth.BondStores", {
                    url: "/BondStores",
                    templateUrl: "templates/modules/applicationmain/master/bondStoresView.html",
                    controller: "bondStoresCtrl",
                    controllerAs: "bondStoresCtrl",
                    params: {
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: 'Bond Stores'
                    }
                }).state("auth.RefundClaim.Update", {
                    url: "/Update/{refId:int}",
                    templateUrl: "templates/modules/applicationmain/customs/refundClaimUpdateView.html",
                    controller: "refundClaimUpdateCtrl",
                    controllerAs: "refundClaimUpdateCtrl",
                    params: {

                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: '{{refundClaimUpdateCtrl.refundClaim.ReferenceNumber}}'
                    }
                }).state("auth.RefundClaim.Update.ConsignmentImport", {
                    url: "/UpdateSF/{conId:int}",
                    templateUrl: "templates/modules/applicationmain/consignment/updateView.html",
                    controller: "consignmentUpdateCtrl",
                    controllerAs: "consignmentUpdateCtrl",
                    params: {
                        conId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{consignmentUpdateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.RefundClaim.Update.PurchaseOrder", {
                    url: "/UpdatePO/{poId:int}",
                    templateUrl: "templates/modules/applicationmain/purchase/updateView.html",
                    controller: "purchaseOrderUpdateCtrl",
                    controllerAs: "pOUpdateCtrl",
                    params: {
                        poId: null
                    },
                    resolve: {},
                    ncyBreadcrumb: {
                        label: '{{pOUpdateCtrl.poNumber ? "PO: " + pOUpdateCtrl.poNumber : "PO"}}'
                    }
                }).state("auth.RefundClaim.Create", {
                    url: "/Create/{refId:int}",
                    templateUrl: "templates/modules/applicationmain/customs/refundClaimCreateView.html",
                    controller: "refundClaimCreateCtrl",
                    controllerAs: "refundClaimCreateCtrl",
                    params: {

                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Create Refund Claim'
                    }
                }).state("auth.RefundClaim.Update.GoodsDec", {
                    url: "/UpdateGoodsDec/{refId:int}",
                    templateUrl: "templates/modules/applicationmain/customs/refundClaimCreateView.html",
                    controller: "refundClaimCreateCtrl",
                    controllerAs: "refundClaimCreateCtrl",
                    params: {

                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Update Refund Claim'
                    }
                }).state("auth.RefundClaim.Update.GoodsDeclaration", {
                    url: "GoodsDeclaration/{gsdId:int}/document/{version:int}",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.GoodsDeclaration
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.gsdId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.RefundClaim.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.Refund
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.refId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("certificateFromRegister", {
                    url: "/certificateFromRegister/{consignmentId:int}/report/{reportId:int}",
                    templateUrl: "templates/modules/reporting/certificateFromRegister.html",
                    controller: "certificateFromRegisterCtrl",
                    controllerAs: "certificateFromRegisterCtrl",
                    params: {
                        consignmentId: null,
                        reportId: null
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: 'Certificate from Register'
                    }
                }).state("auth.CustomsRecon.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.GoodsDeclaration
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.gdId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                }).state("auth.ReportList", {
                    url: "/ReportList",
                    templateUrl: "templates/modules/reporting/listView.html",
                    controller: "reportListCtrl",
                    controllerAs: "reportListCtrl",
                    resolve: {},
                    ncyBreadcrumb: {
                        label: 'Reports'
                    }
                }).state("auth.ReportList.ViewReport", {
                    url: "/ViewReport/{reportId:int}",
                    templateUrl: "templates/modules/reporting/reportParameter.html",
                    controller: "reportParameterCtrl",
                    controllerAs: "reportParameterCtrl",
                    params: {
                        reportId: null
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: '{{reportParameterCtrl.breadCrumbDesc ? reportParameterCtrl.breadCrumbDesc : "Report Parameters"}}'
                    }
                }).state("auth.ComplianceExports", {
                    url: "/ComplianceExports",
                    templateUrl: "templates/modules/applicationmain/compliance/listView.html",
                    controller: "consignmentComplianceListCtrl",
                    controllerAs: "consignmentComplianceListCtrl",
                    params: {
                        FilterList: null,
                        SearchList: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Export Compliance'
                    }
                })
                .state("auth.ComplianceImports", {
                    url: "/ComplianceImports",
                    templateUrl: "templates/modules/applicationmain/compliance/listView.html",
                    controller: "consignmentComplianceListCtrl",
                    controllerAs: "consignmentComplianceListCtrl",
                    params: {
                        FilterList: null
                    },
                    resolve: {
                        isInbound: () => {
                            return true
                        }
                    },
                    ncyBreadcrumb: {
                        label: 'Import Compliance'
                    }
                })
                .state("auth.ComplianceImports.Update", {
                    url: "/Compliance/{transactionId:int}",
                    templateUrl: "templates/modules/applicationmain/compliance/updateView.html",
                    controller: "consignmentComplianceCtrl",
                    controllerAs: "consignmentComplianceCtrl",
                    params: {
                        transactionId: null
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: '{{consignmentComplianceCtrl.breadCrumbDesc ? consignmentComplianceCtrl.breadCrumbDesc : "Update Compliance"}}'
                    }
                })
                .state("auth.ComplianceExports.Update", {
                    url: "/Compliance/{transactionId:int}",
                    templateUrl: "templates/modules/applicationmain/compliance/updateView.html",
                    controller: "consignmentComplianceCtrl",
                    controllerAs: "consignmentComplianceCtrl",
                    params: {
                        transactionId: null
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: '{{consignmentComplianceCtrl.breadCrumbDesc ? consignmentComplianceCtrl.breadCrumbDesc : "Update Compliance"}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentExport.Compliance", {
                    url: "/Compliance/{transactionId:int}",
                    templateUrl: "templates/modules/applicationmain/compliance/updateView.html",
                    controller: "consignmentComplianceCtrl",
                    controllerAs: "consignmentComplianceCtrl",
                    params: {
                        transactionId: null
                    },
                    resolve: {
                        isInbound: () => {
                            return false;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{consignmentComplianceCtrl.breadCrumbDesc ? consignmentComplianceCtrl.breadCrumbDesc : "Update Compliance"}}'
                    }
                }).state("auth.Manifests.Update.ConsignmentImport.Compliance", {
                    url: "/Compliance/{transactionId:int}",
                    templateUrl: "templates/modules/applicationmain/compliance/updateView.html",
                    controller: "consignmentComplianceCtrl",
                    controllerAs: "consignmentComplianceCtrl",
                    params: {
                        transactionId: null,
    
                    },
                    resolve: {
                        isInbound: () => {
                            return true;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{consignmentComplianceCtrl.breadCrumbDesc ? consignmentComplianceCtrl.breadCrumbDesc : "Update Compliance"}}'
                    }
                })
                .state("auth.TariffBrowse", {
                    url: "/TariffBrowse",
                    templateUrl: "templates/modules/applicationmain/tariff/tariffBrowseView.html",
                    controller: "tariffBrowseCtrl",
                    controllerAs: "tariffBrowseCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Tariff Browse'
                    }
                }).state("auth.TariffBrowse.RestrictionDetail", {
                    url: "/RestrictionDetail/{parId:int}",
                    templateUrl: "templates/modules/applicationmain/tariff/prohibitedAndRestrictedUpdateView.html",
                    controller: "prohibitedAndRestrictedUpdateCtrl",
                    controllerAs: "prohibitedAndRestrictedUpdateCtrl",
                    params: {
                    },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Restriction Detail'
                    }
                }).state("auth.AuthDashboard", {
                    url: "/AuthDashboard",
                    templateUrl: "templates/modules/applicationmain/master/authDashboardView.html",
                    controller: "authDashboardCtrl",
                    controllerAs: "authDashboardCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Authorisation Dashboard'
                    }
                }).state("auth.LetterOfCredit.Create", {
                    url: "/LetterOfCredit",
                    templateUrl: "templates/modules/applicationmain/lc/letterOfCreditCreateView.html",
                    controller: "letterOfCreditCreateCtrl",
                    controllerAs: "letterOfCreditCreateCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Create Letter Of Credit'
                    }
                }).state("auth.LetterOfCredit", {
                    url: "/LetterOfCredit",
                    templateUrl: "templates/modules/applicationmain/lc/letterOfCreditListView.html",
                    controller: "letterOfCreditListCtrl",
                    controllerAs: "letterOfCreditListCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Letter Of Credits'
                    }
                }).state("auth.LetterOfCredit.Update.PurchaseOrder", {
                    url: "/LetterOfCreditUpdatePurchaseOrder/{locId:int}",
                    templateUrl: "templates/modules/applicationmain/lc/letterOfCreditCreateView.html",
                    controller: "letterOfCreditCreateCtrl",
                    controllerAs: "letterOfCreditCreateCtrl",
                    params: {
                        ownerEntityId: 0,
                        supplierId: 0,
                        currencyId: 0,
                        creditTypeId: 0,
                        incotermId: 0,
                        locId: null
                    },
                    ncyBreadcrumb: {
                        label: '{{letterOfCreditCreateCtrl.breadCrumbDesc}}'
                    }
                }).state("auth.LetterOfCredit.Update", {
                    url: "/LetterOfCreditUpdate/{lcId:int}",
                    templateUrl: "templates/modules/applicationmain/lc/letterOfCreditUpdateView.html",
                    controller: "letterOfCreditUpdateCtrl",
                    controllerAs: "letterOfCreditUpdateCtrl",
                    params: {
                        lcId: null
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: '{{letterOfCreditUpdateCtrl.breadCrumbDesc ? letterOfCreditUpdateCtrl.breadCrumbDesc : "Update Letter Of Credit"}}'
                    }
                }).state("auth.LetterOfCredit.Update.Documents", {
                    url: "/document/",
                    templateUrl: "templates/modules/documentManagement/moduleDocumentView.html",
                    controller: "moduleDocumentCtrl",
                    controllerAs: "moduleDocumentCtrl",
                    params: {
                    },
                    resolve: {
                        type: () => {
                            return Enum.EnumModelType.LetterOfCredit
                        },
                        referenceId: ($stateParams) => {
                            return $stateParams.lcId;
                        }
                    },
                    ncyBreadcrumb: {
                        label: '{{poDocumentCtrl.breadCrumbDesc ? poDocumentCtrl.breadCrumbDesc : "Documents"}}'
                    }
                })/*.state("auth.MasterData.Account.Create", {
                    url: "/CreateNew",
                    templateUrl: "templates/modules/applicationmain/customs/accountCreateView.html",
                    controller: "accountCreateCtrl",
                    controllerAs: "accountCreateCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Create Customs Account'
                    }
                })*/
                .state("auth.MasterData.Account.Create", {
                    url: "/AccountUpdate/{accId:int}",
                    templateUrl: "templates/modules/applicationmain/customs/accountUpdateView.html",
                    controller: "accountUpdateCtrl",
                    controllerAs: "accountUpdateCtrl",
                    params: {
                        accId: 0
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: '{{accountUpdateCtrl.breadCrumbDesc ? accountUpdateCtrl.breadCrumbDesc : "Create Customs Account"}}'
                    }
                })                
                .state("auth.MasterData.Account", {
                    url: "/Account",
                    templateUrl: "templates/modules/applicationmain/customs/accountListView.html",
                    controller: "accountListCtrl",
                    controllerAs: "accountListCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Customs Account'
                    }                
                }).state("auth.MasterData.Account.Update", {
                    url: "/AccountUpdate/{accId:int}",
                    templateUrl: "templates/modules/applicationmain/customs/accountUpdateView.html",
                    controller: "accountUpdateCtrl",
                    controllerAs: "accountUpdateCtrl",
                    params: {
                        accId: null
                    },
                    resolve: {

                    },
                    ncyBreadcrumb: {
                        label: '{{accountUpdateCtrl.breadCrumbDesc ? accountUpdateCtrl.breadCrumbDesc : "Update Customs Account"}}'
                    }
                }).state("auth.MasterData.CountryVATExceptions", {
                    url: "/CountryVATExceptions",
                    templateUrl: "templates/modules/applicationmain/tariff/countryVATExceptionListView.html",
                    controller: "countryVATExceptionListCtrl",
                    controllerAs: "countryVATExceptionListCtrl",
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'Country VAT Rate Exceptions'
                    }
                }).state("auth.MasterData.HSCodeKeyWords", {
                    url: "/HSCodeKeyWords",
                    templateUrl: "templates/modules/applicationmain/tariff/hsCodeKeyWordView.html",
                    controller: "hsCodeKeyWordtrl",
                    controllerAs: "hsCodeKeyWordtrl",
                    params: {
                     },
                    resolve: {
                    },
                    ncyBreadcrumb: {
                        label: 'HSCode Key Words'
                    }
                })

                //$locationProvider.html5Mode(false).hashPrefix('')

                if (ENV.AZUREAD) {
                    var protectedResourceMap = new Map();
                    protectedResourceMap.set(ENV.DSP_URL, ENV.azureADConfig.consentScopes);

                    msalAuthenticationServiceProvider.init(
                        {
                            clientID: ENV.azureADConfig.clientID,
                            authority: ENV.azureADConfig.authority,
                            tokenReceivedCallback: function (errorDesc, token, error, tokenType) {
                                if (error) {
                                    console.log("Error received: in MSAL callback: " + error);
                                }
                            },
                            //https://www.npmjs.com/package/@azure/msal-angularjs?activeTab=readme
                            optionalParams: {
                                cacheLocation: 'localStorage',
                                validateAuthority: true,
                                redirectUri: ENV.azureADConfig.redirectUri,
                                postLogoutRedirectUri: ENV.azureADConfig.redirectUri,
                                navigateToLoginRequestUrl: true,
                                //unprotectedResources: [],
                                protectedResourceMap: protectedResourceMap,
                                storeAuthStateInCookie: true
                            },
                            routeProtectionConfig: {
                                popUp: true,
                                consentScopes: ENV.azureADConfig.consentScopes,
                            }
                        })
                }

                ;
        };
    };

    angular.module("app").config(config.appConfigUIRoute);
}